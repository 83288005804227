.gallery-slider {
    max-width: 80%;
    margin: 0 auto;
    &__slide {
        *[data-js="cover-img"] {
            padding-top: 75%;
            background-size: contain;
        }
    }
    .slick-dots {
        bottom: -30px;
        & > li {
            display: inline-block;
        }
        & > li:only-child {
            display: none;
        }
    }
}

@media screen and (max-width: $sm) {
    .gallery-slider {
        max-width: 100%;
        &__slide {
            *[data-js="cover-img"] {
                padding-top: 77%;
            }
        }
    }
}
