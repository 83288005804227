.main-carousel-slide {
    .slide-wrapper {
        position: relative;
        padding-top: 53%;
    }
    .main-carousel-slide__bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
    &__content {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 200;
        transform: translate(0, -50%);
        * {
            color: color(2);
            text-shadow: 0 0 20px rgba(0, 0, 0, .5);
        }
        h1, h2 {
            font-family: $f-hammersmith_one;
            font-size: 6.25rem;
            margin-bottom: 1.3125rem;
        }
        p {
            font-family: $f-opensans-sb;
            font-size: 1.3125rem;
            line-height: 2.25rem;
        }
        .btn--default {
            margin-top: 1.8rem;
        }
    }
    &.sub .slide-wrapper {
        padding-top: 30%;
        .main-carousel-slide__content{
            h1, h2 {
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: $xl) {
    .main-carousel-slide {
        &__content {
            h2 {
                font-size: 5rem;
            }
            p {
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .main-carousel-slide {
        .slide-wrapper,
        &.sub .slide-wrapper {
            padding-top: 0;
        }
        .main-carousel-slide__bg-image {
            height: 350px;
            position: relative;
            top: 0;
            left: 0;
            right: auto;
            bottom: auto;
            z-index: 100;
            background-position: 50% 0;
        }
        &__content {
            position: relative;
            top: auto;
            transform: none;
            padding: 0.5rem 0;
            * {
                color: color(4);
                text-shadow: none;
            }
            h1 {
                margin-top: 2rem;
                font-size: 2.5rem;
            }
            h2 {
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
            }
            p {
                font-size: 1rem;
                line-height: normal;
            }
            .btn--default {
                margin-top: 1rem;
                color: color(2);
            }
        }
    }
}

@media screen and (max-width: $md) {
    .main-carousel-slide {
        .main-carousel-slide__bg-image {
            height: 250px;
        }
    }
}

@media screen and (max-width: $sm) {
    .main-carousel-slide {
        .main-carousel-slide__bg-image {
            height: 170px;
        }
    }
}
