/* ------ FontAwesome ------ */


//$fa-font-path: '../../../../../../../node_modules/@fortawesome/fontawesome-free/webfonts/';
//@import "../../../../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
//@import "../../../../../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
//@import "../../../../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
//@import "../../../../../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

/* ------ LOCAL FONTS ------ */
$fonts-path: '../Fonts/';



/* --- MrDeHaviland --- */
/* Regular */
@font-face {
  font-family: 'MrDeHaviland';
  src: url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.eot');
  src: url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.woff2') format('woff2'),
      url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.woff') format('woff'),
      url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.ttf') format('truetype'),
      url('#{$fonts-path}Mr_De_Haviland/MrDeHaviland-Regular.svg#Mr_De_Haviland') format('svg');
  font-weight: bold;
  font-style: normal;
}

/* --- HammersmithOne --- */
/* Regular */
@font-face {
  font-family: 'HammersmithOne';
  src: url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.eot');
  src: url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.woff2') format('woff2'),
      url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.woff') format('woff'),
      url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.ttf') format('truetype'),
      url('#{$fonts-path}Hammersmith_One/HammersmithOne-Regular.svg#HammersmithOne') format('svg');
  font-weight: bold;
  font-style: normal;
}

/* --- OpenSans --- */
/* Bold */
@font-face {
  font-family: 'OpenSans Bold';
  src: url('#{$fonts-path}OpenSans/OpenSans-Bold.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-Bold.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-Bold.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-Bold.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

/* Bold Italic */
@font-face {
  font-family: 'OpenSans Bold Italic';
  src: url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

/* ExtraBold */
@font-face {
  font-family: 'OpenSans ExtraBold';
  src: url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

/* ExtraBold Italic */
@font-face {
  font-family: 'OpenSans ExtraBold Italic';
  src: url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

/* Italic */
@font-face {
  font-family: 'OpenSans Italic';
  src: url('#{$fonts-path}OpenSans/OpenSans-Italic.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-Italic.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-Italic.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-Italic.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: 'OpenSans Light';
  src: url('#{$fonts-path}OpenSans/OpenSans-Light.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-Light.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-Light.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-Light.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: 'OpenSans Light Italic';
  src: url('#{$fonts-path}OpenSans/OpenSans-LightItalic.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-LightItalic.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-LightItalic.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: 'OpenSans Regular';
  src: url('#{$fonts-path}OpenSans/OpenSans-Regular.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-Regular.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-Regular.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-Regular.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* SemiBold */
@font-face {
  font-family: 'OpenSans SemiBold';
  src: url('#{$fonts-path}OpenSans/OpenSans-SemiBold.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBold.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBold.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
}

/* SemiBold Italic */
@font-face {
  font-family: 'OpenSans SemiBold Italic';
  src: url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.eot');
  src: url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype'),
      url('#{$fonts-path}OpenSans/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}
