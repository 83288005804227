.contact-form {
    box-shadow: 0 0 40px rgba(color(1), .25);
    padding: 1rem 0;
    h5 {
        font-family: $f-hammersmith_one;
        color: color(8);
        font-size: 1.125rem;
    }

    @media screen and (max-width: $md) {
        box-shadow: 0 0 0.5rem rgba(color(1), .25);
    }
}
