.breadcrumb-list {
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    li {
      display: inline-block;
      font-family: $f-opensans-r;
      font-size: 1rem;
      color: color(16);
      a {
        color: color(9);
      }
      &:after {
        content: '|';
        display: inline-block;
        font-family: $f-opensans-r;
        font-size: 1rem;
        color: color(9);
        margin: 0 0.25rem;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .breadcrumb-list {
    ul {
      li {
        font-size: 0.8rem;
      }
    }
  }
}
