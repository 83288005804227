.address-block {
    address {
        margin: 0;
        padding: 0;
    }
    * {
        font-family: $f-opensans-b;
        font-size: 0.875rem;
        line-height: 1.75rem;
    }
    h6 {
        color: color(3);
    }
    h4 {
        font-size: 2.5rem;
        margin-bottom: 1.875rem;
    }
    p, address {
        color: color(9);
    }
    a {
        color: color(12);
    }
}
