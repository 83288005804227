.main-nav {
    padding-bottom: 0;
    background-color: color(14);
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
            font-family: $f-hammersmith_one;
            font-size: 0.9375rem;
            text-align: left;
            position: relative;
            padding-bottom: 0.8rem;
            a {
                cursor: pointer;
                display: block;
                padding: 0 0.2rem;
                color: color(4);
                text-decoration: none;
                transition: color .3s ease-in-out;
            }
            &.active, &:hover {
                & > a {
                    color: color(3);
                }
            }
            &:hover {
                & > ul {
                    height: auto;
                    opacity: 1;
                    padding: 0;
                }
            }
            & > ul {
                background-color: color(14);
                display: block;
                position: absolute;
                left: -1rem;
                top: 100%;
                opacity: 0;
                width: 250px;
                height: 0;
                padding: 0;
                overflow: hidden;
                box-shadow: 0 11px 10px rgba(0, 0, 0, .5);
                transition: all .3s ease-in-out;
                li {
                    padding-bottom: 0;
                    &:hover {
                        background-color: rgba(93, 56, 90, 0.1);
                    }
                    &.active a {
                        color: color(2);
                        background-color: rgba(93, 56, 90, 0.5);
                        &::before {
                            color: color(2);
                        }
                    }
                }
                a {
                    display: block;
                    padding: 0.5rem 1rem 0.5rem 2rem;
                    color: color(4);
                    text-decoration: none;
                    transition: color .3s ease-in-out;
                    position: relative;
                    &::before {
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        margin-right: 0.5rem;
                        color: color(8);
                        font-size: 0.9375rem;
                        line-height: 1.5;
                        position: absolute;
                        top: 0.5rem;
                        left: 1rem;
                    }
                }
            }
            &:last-child {
                & > ul {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $xl) {
    .main-nav {
        padding-bottom: 0.6rem;
        ul {
            li {
                font-size: 0.87rem;
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .main-nav {
        padding-bottom: 0;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: auto;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        box-shadow: 0 5px 5px rgba(color(1), .5);
        transition: all .5s ease-in-out;
        & > ul > li {
            & > a {
                //padding-bottom: 0.5rem;;
                //border-bottom: 2px solid color(3);
                background-color: color(3);
                color: color(2);
                padding: 0.5rem 1rem;
                &::before {
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    margin-right: 0.5rem;
                    color: color(2);
                    font-size: 0.9375rem;
                    line-height: 1.5;
                }
            }
        }

        ul {
            width: 100%;
            max-width: 720px;
            margin: 0 auto;
            padding: 1rem 15px;
            flex-direction: column;
            align-items: flex-start;
            li {
                width: 100%;
                margin: 0.5rem 0;
                &.active, &:hover {
                    //& > ul {
                    //padding: 0 10px;
                    //}
                    & > a {
                        color: color(2);
                    }
                }
                a {
                    font-size: 1.2rem;
                }
                & > ul {
                    height: auto;
                    width: 100%;
                    position: relative;
                    top: 0;
                    left: 0;
                    opacity: 1;
                    box-shadow: none;
                    a {
                        padding: 0.25rem 1rem 0.25rem 2rem;
                    }
                }
            }
        }
    }
    .btn--burger {
        &.active {
            & + .main-nav {
                opacity: 1;
                max-height: 300vh;
            }
        }
    }
}

@media screen and (max-width: $md) {
    .main-nav {
        ul {
            max-width: 540px;
        }
    }
}

@media screen and (max-width: $sm) {
    .main-nav {
        ul {
            max-width: 100%;
        }
    }
}
