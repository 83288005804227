.form {
    &--contact {
        input, textarea, .form-group, .form-check {
            display: block;
            width: 100%;
            margin-top: 1.5rem;
            margin-bottom: 0;
            padding: .9rem 1.25rem;
            border: none;
            background-color: color(17);
            font-family: $f-opensans-r;
            font-size: 1.0625rem;
            color: color(1);
            @include placeholder {
                color: color(9);
                opacity: 1;
            }
        }

        textarea {
            min-height: 140px;
            resize: vertical;
        }
        button {
            margin-top: 1.5rem;
        }
        .form-check {
            input {
                height: 1.59375rem;
                margin-top: 0;
                margin-left: 0;
            }
            label {
                font-family: $f-opensans-r;
                font-size: 1.0625rem;
                margin-bottom: 0;
                padding-left: 1.5rem;
                color: color(1);
            }
        }
        .form-group {
            display: flex;
            label {
                flex: 0 0 auto;
                display: inline-block;
                margin: 0;
                padding-right: 1rem;
            }

            select.form-control {
                display: inline-block;
                height: 1.59375rem;
                border: none;
                padding: 0;
                border-radius: 0;
                font-family: $f-opensans-r;
                font-size: 1.0625rem;
                color: color(9);
                background-color: color(2);
            }
        }


    }
}

@media screen and (max-width: $lg) {
    .form {
        &--contact {
            input, textarea, .form-group, .form-check {
                font-size: 1rem;
            }
            .form-check {
                input {
                    height: 1.5rem;
                }
                label {
                    font-size: 1rem;
                    padding-left: 2rem;
                }
            }
            .form-group {
                select.form-control {
                    height: 1.5rem;
                    font-size: 1rem;

                }
            }
        }
    }
}

@media screen and (max-width: $sm) {
    .form {
        &--contact {
            .form-group {
                flex-direction: column;
                label {
                    flex: 0 0 100%;
                    display: block;
                    padding-right: 0;
                    margin-bottom: 1rem;
                }
                select.form-control {
                    flex: 0 0 100%;
                    display: block;
                }
            }
        }
    }
}
