.comments-wrapper {
  &:nth-child(3n-2) {
    color: color(5);
  }
  &:nth-child(3n-1) {
    color: color(6);
  }
  &:nth-child(3n) {
    color: color(7);
  }
}