.page-title,
.page-text {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: $f-hammersmith_one;
        text-transform: uppercase;
        font-size: 4.125rem;
        color: color(1);
        text-align: left;
        margin-bottom: 3.75rem;
    }
    h2 {
        text-transform: none;
        font-size: 2.5rem;
        margin-bottom: 1rem;
        & + p,
        & + .gallery-grid,
        & + ul,
        & + .rooms-list {
            margin-top: 1rem;
        }
    }
    .gallery-grid {
        margin-top: 3.75rem;
        & + p,
        & + .gallery-grid,
        & + ul {
            margin-top: 2rem;
        }
    }
    p {
        font-family: $f-opensans-r;
        font-weight: normal;
        font-size: 1.0625rem;
        line-height: 1.6875rem;
        color: color(9);
        //max-width: 600px;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        & + p,
        & + .gallery-grid {
            margin-top: 2rem;
        }
        & + ul {
            margin-top: 1rem;
        }
    }
    strong {
        font-family: $f-opensans-b;
        font-weight: normal;
    }
    ul {
        margin: 0.5rem auto 0;
        li {
            font-family: $f-opensans-r;
            font-weight: normal;
            font-size: 1.0625rem;
            line-height: 2.25rem;
            color: color(9);
        }
        & + .gallery-grid,
        & + ul {
            margin-top: 2rem;
        }
        & + p {
            margin-top: 1rem;
        }
    }

    a {
        font-family: $f-opensans-b;
        color: color(12);
        &:hover {
            text-decoration: underline;
        }
        &.btn.btn--default {
            color: color(2);
            &:hover {
                text-decoration: none;
            }
        }
    }

    .rooms-list {
        display: block;
        //max-width: 600px;
        list-style: none;
        margin: 3.5rem auto 0;
        padding: 0;
        li {
            display: flex;
            justify-content: space-between;
            font-family: $f-opensans-b;
            font-weight: normal;
            font-size: 1.0625rem;
            line-height: 1.5rem;
            padding: 0.5rem 0;
            color: color(9);
            span {
                flex: 1;
                display: block;
                &:first-child {
                    max-width: 80%;
                }
                &:last-child {
                    text-align: left;
                    color: color(3);
                }
            }
        }
    }

    .address-block {
        address {
            margin: 0;
            padding: 0;
        }
        * {
            font-family: $f-opensans-b;
            font-size: 0.875rem;
            line-height: 1.75rem;
        }
        h6 {
            color: color(3);
            margin: 0;
        }
        h4 {
            font-size: 2.5rem;
            margin-bottom: 1.875rem;
        }
        p, address {
            margin: 0;
            color: color(9);
        }
        a {
            margin: 0;
            color: color(12);
        }
    }


    @media screen and (max-width: $lg) {
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 3.125rem;
        }
        h2 {
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
            //margin-top: 2.75rem;
        }
        .rooms-list {
            margin-top: 2.5rem;
            li {
                font-size: 1rem;
                line-height: 1.5rem;
                //margin-bottom: .8rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and (max-width: $md) {
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 1.75rem;
        }
    }

    @media screen and (max-width: $sm) {
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: $xs) {
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 1.75rem;
        }
        h2 {
            font-size: 1.25rem;
        }
    }
}
