.comment-block {
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  .icon-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background-color: currentColor;
  }
  .comment-area {
    max-width: calc(100% - 53px);
    padding-left: 1.1875rem;
  }
  i {
    font-size: 1.625rem;
    color: color(2);
  }
  h3 {
    font-family: $f-hammersmith_one;
    font-size: 1.4375rem;
    margin-bottom: 1.75rem;
  }
  p {
    font-family: $f-opensans-r;
    font-size: 1rem;
    line-height: 1.75rem;
    color: color(9);
  }
}

@media screen and (max-width: $lg) {
  .comment-block {
    .icon-area {
      width: 43px;
      height: 43px;
    }
    .comment-area {
      max-width: calc(100% - 43px);
      padding-left: 1rem;
    }
    i {
      font-size: 1.2rem;
    }
    h3 {
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  }
}