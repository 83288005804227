.content-line {
    padding: 3rem 0;
    &--person {
        padding-bottom: 0;
    }
    &--inner-page {
        padding-bottom: 3rem;
        &.pt-normal {
            padding-top: 3rem;
        }
    }
    &--contact {
        padding-bottom: 3rem;
        .address-block {
            * {
                font-size: 1.0625rem;
            }
            h4 {
                font-size: 2.5rem;
                margin-bottom: 1.875rem;
            }
        }
    }
}

@media screen and (max-width: $xl) {
    .content-line {
        &--contact {
            .address-block {
                * {
                    font-size: 0.9rem;
                }
                h4 {
                    font-size: 2rem;
                    margin-bottom: 1.275rem;
                }
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .content-line {
        padding: 2.5rem 0;
        &--inner-page {
            padding: 0 0 1.75rem;
            &.pt-normal {
                padding-top: 2.5rem;
            }
        }
        &--contact {
            padding-bottom: 2.5rem;
            .address-block {
                h4 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
