.gallery-carousel {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(color(1), .8);
  overflow: auto;
  .close-btn {
    display: block;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    right: 20px;
    color: color(2);    
  }
}