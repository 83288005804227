.page-title-line {
    padding: 3rem 0;
    &--contact {
        .page-title,
        .page-text {
            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                text-align: left;
                text-transform: none;
            }
        }
    }
}
