.footer {
  padding: 17px 0 11px;
  font-family: $f-opensans-b;
  color: color(9);
  font-size: 0.75rem;
  position: relative;
  background-color: color(14);
  .scroll-top-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: color(13);
    position: absolute;
    right: 20px;
    bottom: calc(100% + 20px);
    z-index: 200;
    transition: all .3s ease-in-out;
    &:hover {
      background-color: lighten(color(13), 2%);
    }
    &:after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-top: 3px solid color(2);
      border-left: 3px solid color(2);
      transform: translate(0, 15%) rotate(45deg);
    }
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    li {
      display: inline-block;
      a {
        font-size: 0.75rem;
        color: currentColor;
      }
    }
  }
  .copyright-side {
    ul {
      li {
        &:before {
          content: '.';
          display: inline-block;
          font-family: $f-opensans-b;
          font-size: 0.75rem;
          margin: 0 7px;
        }
      }
    }
  }
  .socials-side {
    ul {
      li {
        margin: 0 24px 0 0;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 1rem;
          color: color(12);
          transition: all .3s ease-in-out;
          &:hover {
            color: color(3);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .footer {
    .scroll-top-btn {
      width: 38px;
      height: 38px;
      right: 10px;
      bottom: calc(100% + 10px);
    }
    .copyright-side {
      ul {
        display: block;
        margin: 0.5rem 0;
        li {
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}