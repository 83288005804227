.header {
  position: relative;
  z-index: 8888;
  padding-bottom: 1.75rem;
  background-color: color(14);
  .row {
    position: static;
  }
  .logo {
    display: block;
    max-width: 262px;
  }
  &__main-title {
    margin-bottom: 2.5rem;
    * {
      font-family: $f-hammersmith_one;
      font-size: 3rem;
      color: color(3);
    }
  }
}

@media screen and (max-width: $lg) {
  .header {
    .logo {
      max-width: 182px;
    }
    &__main-title {
      text-align: right;
      margin-bottom: 1.5rem;
      * {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .header {
    &__main-title {
      //margin-bottom: 2.5rem;
      * {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .header {
    .logo {
      max-width: 130px;
    }
    &__main-title {
      * {
        float: right;
        font-size: 1.2rem;
        white-space: nowrap;
      }
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
}
