.person-block {
  &__inf {
    padding-top: 7.5rem;    
  }
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  p {
    font-family: $f-opensans-r;
    font-size: 1rem;
    color: color(9);
  }
  .signature {
    margin-top: 1.875rem;
    font-family: $f-mrdehaviland;
    font-size: 2.5rem;
    color: color(10);
  }
}

@media screen and (max-width: $lg) {
  .person-block {
    &__inf {
      padding-top: 0;
      p {
        font-size: 0.9rem;
      }
      .signature {
        margin-top: 1rem;
        font-size: 1.2rem;
      }
    }
  }
}