.teaser {
    min-height: 100%;
    &--1 {
        color: color(2);
        padding: 3.75rem 4.625rem 4rem;
        transform-origin: 50% 50%;
        transition: all .3s ease-in-out;
        &:hover {
            transform: scale(1.025);
            box-shadow: 0 0 20px rgba(0, 0, 0, .5);
        }
        &__icon {
            font-size: 2.5rem;
            margin-bottom: 2.8rem;
            text-align: center;
        }
        &__title {
            font-family: $f-hammersmith_one;
            margin-bottom: 2rem;
            * {
                font-size: 2.625rem;
                display: inline-block;
            }
        }
        &__desc {
            font-family: $f-opensans-r;
            font-size: 1rem;
            line-height: 1.75rem;
            margin-bottom: 2rem;
        }
        &__link {
            font-family: $f-hammersmith_one;
            font-size: 1.25rem;
            a {
                color: currentColor;
            }
        }
    }
    &--2 {
        text-align: center;
        position: relative;
        &__thumbnail {
            display: block;
            padding-top: 90%;
        }
        &__link {
            margin-top: 1rem;
            a {
                font-family: $f-hammersmith_one;
                font-size: 1.5rem;
                color: color(10);
            }
        }
        &__desc {
            padding: 0;
            background-color: color(2);
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 200;
            width: 100%;
            height: auto;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: all .3s ease-in-out;
        }
        &:hover {
            .teaser--2__desc {
                max-height: 200vh;
                opacity: 1;
                padding: 15px;
                box-shadow: 0 5px 5px rgba(color(1), .5);
            }
        }
    }
    &--3 {
        text-align: center;
        position: relative;
        &__thumbnail {
            display: block;
            padding-top: 60%;
        }
        &__link {
            margin-top: 0.5rem;
            a {
                font-family: $f-hammersmith_one;
                font-size: 1rem;
                color: color(10);
            }
        }
        &__desc {
            padding: 0;
            background-color: color(2);
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 200;
            width: 100%;
            height: auto;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: all .3s ease-in-out;
        }
        &:hover {
            .teaser--2__desc {
                max-height: 200vh;
                opacity: 1;
                padding: 15px;
                box-shadow: 0 5px 5px rgba(color(1), .5);
            }
        }
    }
}

@media screen and (max-width: $xxl) {
    .teaser {
        &--1 {
            padding-left: 1rem;
            padding-right: 1rem;
            &__title {
                * {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: $xl) {
    .teaser {
        &--1 {
            &__title {
                * {
                    font-size: 2.2rem;
                }
            }
        }
    }
}

@media screen and (max-width: $lg) {
    .teaser {
        &--1 {
            padding: 1.5rem;
            &__icon {
                font-size: 2.2rem;
                margin-bottom: 1.5rem;
            }
            &__title {
                margin-bottom: 1.5rem;
                * {
                    font-size: 1.5rem;
                }
            }
            &__desc {
                font-size: 0.9rem;
                line-height: 1.45rem;
                margin-bottom: 1.5rem;
            }
        }
        &--2 {
            &__desc {
                position: relative;
                max-height: 100%;
                padding: 5px;
                opacity: 1;
            }
            &:hover {
                .teaser--2__desc {
                    padding: 5px;
                    box-shadow: none;
                }
            }
        }
    }
}

@media screen and (max-width: $md) {
    .teaser {
        &--1 {
            padding: 1rem;
            &__title {
                padding-left: 0;
                * {
                    font-size: 1.8rem;
                }
            }
        }
        &--2 {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            &__thumbnail {
                padding-top: 50%;
            }
        }
        &--3 {
            //max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            &__thumbnail {
                padding-top: 40%;
            }
        }
    }
}
