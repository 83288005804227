.gallery-grid {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    justify-content: start;

    &__item {
        width: 33.33%;
        *[data-js="cover-img"] {
            display: block;
            padding-top: 80%;
            transform-origin: 50% 50%;
            cursor: pointer;
            transition: all .3s ease-in-out;
            position: relative;
            z-index: 100;
            &:hover {
                transform: scale(1.025);
                box-shadow: 0 0 10px rgba(0, 0, 0, .5);
                z-index: 200;
            }
        }
        @media screen and (max-width: $sm) {
            width: 100%;
            *[data-js="cover-img"] {
                padding-top: 50%;
            }
        }
    }

    &.col2 .gallery-grid__item {
        width: 50%;
        *[data-js="cover-img"] {
            padding-top: 50%;
        }
        @media screen and (max-width: $sm) {
            width: 100%;
            *[data-js="cover-img"] {
                padding-top: 50%;
            }
        }
    }
}
