/* Dots */
.slick-dots {
  list-style: none;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 4px;
    button {
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      width: 10px;
      height: 10px;
      background-color: color(2);
      border-radius: 50%;
      transition: all .3s ease-in-out;
    }
    &:hover, &.slick-active {
      button {
        background-color: color(3);
      }
    }
  }
}

.slick {
  &-arrow {
    font-size: 0;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: none;
    border-top: 3px solid color(2);
    border-left: 3px solid color(2);
    transform-origin: 50% 50%;
    border-top-left-radius: 3px;
    position: absolute;
    top: 50%;
    z-index: 200;
    transition: all .3s ease-in-out;
    outline: none !important;
    &:hover {
      border-color: color(3);
    }
  }
  &-prev {
    left: -30px;
    transform: translate(0, -50%) rotate(-45deg);
  }
  &-next {
    right: -30px;
    transform: translate(0, -50%) rotate(135deg);
  }
}