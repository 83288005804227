.after-main-carousel-line {
  position: relative;
  z-index: 7777;
  margin-top: -4.75rem;
}

@media screen and (max-width: $xl) {
  .after-main-carousel-line {
    margin-top: -2.75rem;
  }
}

@media screen and (max-width: $lg) {
  .after-main-carousel-line {
    margin-top: 0;
  }
}