.btn {
  display: inline-block;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  &:hover, &:focus, &:visited, &:active {
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }
  &--burger {
    display: none;
    width: 40px;
    height: 24px;
    color: color(3);
    position: relative;
    z-index: 6666;
    outline: none;
    &:focus {
      box-shadow: none;
    }
    span, &:before, &:after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: currentColor;
      position: absolute;
      left: 0;
      transform-origin: 50% 50%;
      transition: all .3s ease-in-out, width .3s ease-in-out, color 1.5s ease-in-out;
    }
    &:before {
      top: 0;
    }
    span {
      top: 50%;
      transform: translate(0, -2px);
    }
    &:after {
      top: 100%;
      transform: translate(0, -100%);
    }
    &:active, &:visited, &:focus {
      color: color(3);
    }
    &:hover {
      color: color(3);
      &:after {
        width: 100%;
      }
    }
    &.active {
      span {
        opacity: 0;
      }
      &:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
      }
      &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }
  &--default {
    text-align: center;
    font-family: $f-opensans-b;
    background-color: color(3);
    color: color(2);
    font-size: 1.25rem;
    padding: .9rem 1.75rem;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    &:hover, &:focus, &:visited, &:active {
      color: color(2);
    }
    &:hover {
      background-color: lighten(color(3), 5%);
    }
  }
}

@media screen and (max-width: $lg) {
  .btn {
    &--burger {
      display: inline-block;
    }
    &--default {
      font-size: 0.8rem;
      padding: .6rem 1.25rem;
    }
  }
}