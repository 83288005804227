body,
html {
    min-width: 320px;
    font-size: 16px;
}

*[data-js="cover-img"] {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &.cover-init {
        overflow: hidden;

        img {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

section {
    position: relative;
}

.divider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: color(15);
}

@media screen and (max-width: $lg) {

    body,
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: $sm) {

    body,
    html {
        font-size: 12px;
    }
}

.incentive {
    position: fixed;
    z-index: 9999;
    background-color: color(7);
    color: color(2);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    padding: 20px 40px;
    max-width: calc(100% - 20px);
    top: 10px;
    right: 10px;

    p+p {
        margin-top: 10px;
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
        z-index: 1;
        color: color(2);
    }
}
