.section-title {
  margin-bottom: 5.3rem;
  * {
    font-family: $f-hammersmith_one;
  }
  h5 {
    color: color(8);
    font-size: 1.0625rem;
    margin-bottom: 2.8rem;
  }
  h2 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: $lg) {
  .section-title {
    margin-bottom: 1.5rem;
    h5 {
      margin-bottom: 1.2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
}