/* ----- Colors ----- */
$colors: (
  #000000, /*  1 */
  #ffffff, /*  2 */
  #5d385a, /*  3 */
  #2f2f2f, /*  4 */
  #014a85, /*  5 */
  #fbbb01, /*  6 */
  #5faf31, /*  7 */
  #9c9c9c, /*  8 */
  #646464, /*  9 */
  #333333, /* 10 */
  #ededed, /* 11 */
  #323232, /* 12 */
  #0bd2cd, /* 13 */
  #f8f8f8, /* 14 */
  #eeeeee, /* 15 */
  #262a33, /* 16 */
  #f6f6f6  /* 17 */
);

@function color($index) {
  @return nth($colors, $index);
}

/* Color helper classes */
@for $i from 1 through length($colors) {
  .clr-#{$i} {
    color: color($i);
    * {
      color: color($i);
    }
  }
  .bg-clr-#{$i} {
    background-color: color($i);
  }
}
/* --------------------- */

/* ----- Screen sizes ----- */
$xxl: 1400.5px;
$xl: 1199.5px;
$lg: 991.5px;
$md: 767.5px;
$sm: 575.5px;
$xs: 320.5px;
/* --------------------- */

/* ----- Fonts ----- */
/* MrDeHaviland */
$f-mrdehaviland: 'MrDeHaviland';

/* HammersmithOne */
$f-hammersmith_one: 'HammersmithOne';

/* Open Sans */
$f-opensans-b: 'OpenSans Bold';
$f-opensans-bi: 'OpenSans Bold Italic';
$f-opensans-eb: 'OpenSans ExtraBold';
$f-opensans-ebi: 'OpenSans ExtraBold Italic';
$f-opensans-i: 'OpenSans Italic';
$f-opensans-l: 'OpenSans Light';
$f-opensans-li: 'OpenSans Light Italic';
$f-opensans-r: 'OpenSans Regular';
$f-opensans-sb: 'OpenSans SemiBold';
$f-opensans-sbi: 'OpenSans SemiBold Italic';
/* --------------------- */

// Mixins
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }  
}