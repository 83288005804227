.main-carousel {
  text-align: center;
  *[data-js="main-carousel"] {
    display: none;
    &.slick-slider {
      display: block;
    }
  }
  .slick-dots {
    display: block;
    width: auto;
    left: 50%;
    bottom: 140px;
    transform: translate(-50%, 0);
      & > li {
          display: inline-block;
      }
      & > li:only-child {
          display: none;
      }
  }
}

@media screen and (max-width: $xxl) {
  .main-carousel {
    .slick-dots {
      bottom: 80px;
    }
  }
}

@media screen and (max-width: $xl) {
  .main-carousel {
    .slick-dots {
      bottom: 50px;
    }
  }
}

@media screen and (max-width: $lg) {
  .main-carousel {
    .slick-dots {
      bottom: auto;
      top: 320px;
    }
  }
}

@media screen and (max-width: $md) {
  .main-carousel {
    .slick-dots {
      bottom: auto;
      top: 220px;
    }
  }
}

@media screen and (max-width: $sm) {
  .main-carousel {
    .slick-dots {
      bottom: auto;
      top: 150px;
    }
  }
}
